import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';
import theme from './assets/themes';
import './styles.css';
import ProtectedRoute from './ProtectedRoute';
import ProfLecDash from './toolspage/ProfLecDash';

const LazyHomePage = lazy(() => import('./HomePage'));
const LazyLogin = lazy(() => import('./Login'));
const LazySignup = lazy(() => import('./Signup'));
const LazyAbout = lazy(() => import('./pages/about'));
const LazyFeatures = lazy(() => import('./pages/features'));
const LazyResources = lazy(() => import('./pages/resources'));
const LazyPrice = lazy(() => import('./pages/price'));
const LazyRealTimeFeedback = lazy(() => import('./pages/real-time-feedback'));
const LazyPostLectureInsights = lazy(() => import('./pages/post-lecture-insights'));
const LazyChooseAccountType = lazy(() => import('./ChooseAccountType'));
const LazyCreateProfile1 = lazy(() => import('./CreateProfile1'));
const LazyLanding = lazy(() => import('./Productpage/Landing'));
const LazyLectures = lazy(() => import('./Productpage/Lectures'));
const LazySettings = lazy(() => import('./Productpage/Settings'));
const LazyArchivedCourses = lazy(() => import('./Productpage/ArchivedCourses'));
const LazyConfusion = lazy(() => import('./toolspage/Confusion'));
const LazyProfLecDash = lazy(() => import('./toolspage/ProfLecDash'));

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LazyHomePage />} />
            <Route path="/login" element={<div className='container'><LazyLogin /></div>} />
            <Route path="/signup" element={<div className='container'><LazySignup /></div>} />
            <Route path="/pages/about" element={<LazyAbout />} />
            <Route path="/pages/features" element={<LazyFeatures />} />
            <Route path="/pages/resources" element={<LazyResources />} />
            <Route path="/pages/price" element={<LazyPrice />} />
            <Route path="/choose-account-type" element={<ProtectedRoute element={<LazyChooseAccountType />} />} />
            <Route path="/create-profile/:type" element={<LazyCreateProfile1 />} />
            <Route path="/Productpage/courses" element={<LazyLanding />} />
            <Route path="/Productpage/courses/:courseCode/lectures" element={<LazyLectures />} />
            <Route path="/Productpage/settings" element={<LazySettings />} />
            <Route path="/Productpage/archived-courses" element={<LazyArchivedCourses />} />
            <Route path="/pages/features/real-time-feedback" element={<LazyRealTimeFeedback />} />
            <Route path="/pages/features/post-lecture-insights" element={<LazyPostLectureInsights />} />
            <Route path="/Productpage/courses/:courseCode/lectures/:lectureId/confusion" element={<LazyConfusion />} />
            <Route path="/Productpage/courses/:courseCode/lectures/:lectureId/ProfLecDash" element={<LazyProfLecDash />} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default App;
