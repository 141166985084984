import React, { useState, useEffect } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Typography, Input } from '@mui/material';
import { getStorage, ref, listAll, getDownloadURL, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { storage, db } from '../firebase'; // Ensure firebase config is correctly imported

const ProfilePicture = ({ userId }) => {
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [editPictureDialogOpen, setEditPictureDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState('');
  const [userName, setUserName] = useState(''); // State to store user's name
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) return;

      const userDocRef = doc(db, 'users', userId);

      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserName(data.name || ''); // Fetch user's name
          const userFolderRef = ref(storage, `profilePictures/${userId}`);
          console.log('userId:', userId);

          try {
            const listResult = await listAll(userFolderRef);
            if (listResult.items.length > 0) {
              const profilePicRef = listResult.items[0];
              const url = await getDownloadURL(profilePicRef);
              setProfilePicUrl(url);
            } else {
              setProfilePicUrl(null);
            }
          } catch (error) {
            console.error('Error fetching profile picture:', error);
            setProfilePicUrl(null);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditName = () => {
    handleMenuClose();
    setEditNameDialogOpen(true);
  };

  const handleEditNameDialogClose = () => {
    setEditNameDialogOpen(false);
  };

  const handleEditPicture = () => {
    handleMenuClose();
    setEditPictureDialogOpen(true);
  };

  const handleEditPictureDialogClose = () => {
    setEditPictureDialogOpen(false);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleNameSubmit = async () => {
    if (!newName) {
      setError('Please enter a name.');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, { name: newName });
      console.log('Name updated successfully');
      setUserName(newName); // Update user name in state
    } catch (error) {
      console.error('Error updating name:', error);
      setError('Failed to update name.');
    }

    setNewName('');
    handleEditNameDialogClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePicture(file);
    }
  };

  const handleFileUpload = async () => {
    if (!profilePicture) {
      setError('No picture selected.');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      const oldPhotoUrl = userDoc.data()?.photo;

      if (oldPhotoUrl) {
        const oldProfilePictureRef = ref(storage, oldPhotoUrl);
        await deleteObject(oldProfilePictureRef);
      }

      const storageRef = ref(storage, `profilePictures/${userId}/${profilePicture.name}`);
      const uploadTask = uploadBytesResumable(storageRef, profilePicture);

      uploadTask.on('state_changed', 
        (snapshot) => {
          setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (error) => {
          console.error('Error uploading profile picture:', error);
          setError('Error uploading profile picture. Please try again.');
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await updateDoc(userDocRef, { photo: downloadURL });
          setProfilePicUrl(downloadURL);
          console.log('Profile picture uploaded successfully');
          setProfilePicture(null);
          setUploadProgress(0);
          handleEditPictureDialogClose();
        }
      );
    } catch (error) {
      console.error('Error updating profile picture:', error);
      setError('Error updating profile picture. Please try again.');
    }
  };

  return (
    <Box>
      <IconButton onClick={handleMenuClick}>
        <Avatar alt="User Profile" src={profilePicUrl || '/static/images/avatar/1.jpg'} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userName && (
          <MenuItem disabled>
            <Typography variant="body1" fontWeight="bold">{userName}</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleEditName}>Edit Name</MenuItem>
        <MenuItem onClick={handleEditPicture}>Edit Picture</MenuItem>
      </Menu>

      {/* Edit Name Dialog */}
      <Dialog open={editNameDialogOpen} onClose={handleEditNameDialogClose}>
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Name"
            type="text"
            fullWidth
            variant="standard"
            value={newName}
            onChange={handleNameChange}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditNameDialogClose}>Cancel</Button>
          <Button onClick={handleNameSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Picture Dialog */}
      <Dialog open={editPictureDialogOpen} onClose={handleEditPictureDialogClose}>
        <DialogTitle>Upload New Picture</DialogTitle>
        <DialogContent>
          <Input
            type="file"
            onChange={handleFileChange}
            fullWidth
          />
          {profilePicture && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                {profilePicture.name}
              </Typography>
              {uploadProgress > 0 && <Typography variant="body2">{`Upload Progress: ${Math.round(uploadProgress)}%`}</Typography>}
              {error && <Typography color="error">{error}</Typography>}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditPictureDialogClose}>Cancel</Button>
          <Button onClick={handleFileUpload} variant="contained" color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfilePicture;
