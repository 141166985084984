import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import ProfilePicture from '../components/ProfilePicture';
import Sidebar from "../components/Sidebar";
import theme from "../assets/themes";
import Homebar from '../components/Homebar';
import axios from "axios";
import {
  Box,
  AppBar,
  Typography,
  Toolbar,
  Avatar,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Menu,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
} from "recharts";
import { app, db } from "../firebase";
import "firebase/firestore";
import { onSnapshot, collection, orderBy, query, doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import profanity from "bad-words";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import zIndex from "@mui/material/styles/zIndex";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    minWidth: '100vw',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '14rem', 
    paddingBottom: '5rem', 
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '12rem', 
    },
  },
  burger: {
    position: "relative",
    width: "30px",
    height: "22.5px",
    background: "transparent",
    cursor: "pointer",
    display: "block",
  },
  content: {
    padding: theme.spacing(3),
    marginTop: "8rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: "6rem",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "18.75rem",
      paddingRight: "18.75rem",
    },
  },
  menuButton: {
    marginRight: "1.563rem",
    maxWidth: "26px",
  },
  input: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  span: {
    display: "block",
    position: "absolute",
    height: "3.75px",
    width: "100%",
    background: `${theme.palette.common.white} !important`,
    borderRadius: "9px",
    opacity: 1,
    left: 0,
    transform: "rotate(0deg)",
  },
  span1: {
    top: "0px",
  },
  span2: {
    top: "50%",
  },
  span3: {
    top: "100%",
  },
  graphContainer: {
    height: "300px",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(4),
    paddingBottom: "40px",
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important',
    borderRadius: '20px !important',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(0),
    },
  },
  chatContainer: {
    height: "400px",
    overflowY: "auto",
    overflowX: "hidden", // Ensures no horizontal scroll
    padding: theme.spacing(4),
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important',
    borderRadius: '20px !important',
    overflowY: 'scroll', 
    scrollbarWidth: 'none', 
    '-ms-overflow-style': 'none',  
    '&::-webkit-scrollbar': {
      display: 'none',  
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  messageList: {
    padding: 0,
  },
  messageItem: {
    padding: theme.spacing(1),
    height: "auto",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    wordBreak: 'break-word',
    display: 'flex',
    flexDirection: 'row',  // Change from column to row
    justifyContent: 'space-between',  // Space between text and like button
    alignItems: 'center',  // Vertically center items
  },
  likeButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  messageInput: {
    marginTop: theme.spacing(2),
    position: 'fixed',
    '& .MuiOutlinedInput-root': {
      borderRadius: '16px',
    },
  },
  messageListBox: {
    maxHeight: '250px',
  },
  messageContainer: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
  },
  replyList: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  replyItem: {
    padding: theme.spacing(0.5, 0),
  },
  homebarContainer: {
    width: "100%",
    position: 'fixed',
    bottom: 30,
    zIndex: 1100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messagesBox: {
    flexGrow: 1,
    overflowY: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none', 
    },
    '-ms-overflow-style': 'none', 
    'scrollbar-width': 'none', 
  },
}));

export const Hamburger = ({ toggleSidebar }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.burger} onClick={toggleSidebar}>
      <Box className={`${classes.span} ${classes.span1}`} />
      <Box className={`${classes.span} ${classes.span2}`} />
      <Box className={`${classes.span} ${classes.span3}`} />
    </IconButton>
  );
};

const ConfusionGraph = () => {
  const classes = useStyles();
  const { lectureId } = useParams();
  const existingDocIds = new Set();
  const [isLectureTimeLoaded, setIsLectureTimeLoaded] = useState(false);
  const [lectureLength, setLectureLength] = useState("");

  useEffect(() => {
    async function fetchLectureTime() {
      try {
        console.log(lectureId);
        const response = await axios.post(
          process.env.REACT_APP_GET_LECTURE_TIME,
          {
            lectureId: lectureId, // Passes the lecture ID to the server
          }
        );

        if (response.status === 200) {
          const regStartTime = convertMilitaryToHour(response.data.startTime); // Adjusted for startTime
          console.log(response.data);
          
          // Calculate the lecture length using startTime and endTime
          setLectureLength(getLectureLength(response.data.startTime, response.data.endTime));
          
          setLectureTime(regStartTime); // Updated for startTime
          setIsLectureTimeLoaded(true);
        } else if (response.status === 404) {
          console.error("Lecture not found:", response.message);
        }
      } catch (error) {
        console.error("Error fetching lecture time:", error.message);
      }
    }

    fetchLectureTime();
  }, [lectureId]);


  function getLectureLength(start, end) {
    if (!start || !end) {
      console.error("Invalid time values:", start, end);
      return "00:00";
    }
  
    const [startHours, startMinutes] = String(start).split(':').map(Number);
    const [endHours, endMinutes] = String(end).split(':').map(Number);
  
    if (isNaN(startHours) || isNaN(startMinutes) || isNaN(endHours) || isNaN(endMinutes)) {
      console.error("Invalid number conversion:", start, end);
      return "00:00";
    }
  
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
  
    let lengthMinutes = endTotalMinutes - startTotalMinutes;
  
    if (lengthMinutes < 0) {
      lengthMinutes += 24 * 60;
    }
  
    const lengthHours = Math.floor(lengthMinutes / 60);
    lengthMinutes = lengthMinutes % 60;
  
    return `${String(lengthHours).padStart(2, '0')}:${String(lengthMinutes).padStart(2, '0')}`;
  }

  const convertMilitaryToHour = (militaryTime) => {
    const [hours, minutes] = militaryTime.split(':').map(Number);
    let regularHours = hours % 12;
    regularHours = regularHours === 0 ? 12 : regularHours; 
    const formattedMinutes = minutes.toString().padStart(2, '0'); 
    return `${regularHours}:${formattedMinutes}`;
  };

  function generateLectureData(lectureLength) {
    if (!lectureLength) return [];
    const [hours, minutes] = lectureLength.split(':').map(Number);

    const maxMinutes = hours * 60 + minutes;
  
    for (let minute = 0; minute <= maxMinutes; minute++) {
      const hour = Math.floor(minute / 60);
      const minutePart = minute % 60;
      const timeString = `${hour}:${minutePart.toString().padStart(2, '0')}`;
      data.push({ time: timeString, confusion: 0, discussion: 0 });
    }
    return data;
  }

  useEffect(() => {
    setData(generateLectureData(lectureLength));
  }, [lectureLength]);
  
  const [lectureTime, setLectureTime] = useState("");
  const [data, setData] = useState([]);

  console.log(data)

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_GET_CONFUSION_SIGNAL, { 
          lectureId: lectureId,
        });
        const newData = response.data.confusionSignals;
        
        if (isLectureTimeLoaded) {
          updateData(newData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isLectureTimeLoaded) {
      fetchData(); 
      intervalId = setInterval(fetchData, 5000000); 
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); 
      }
    };
  }, [lectureId, isLectureTimeLoaded]);

  const updateData = (newData) => {
    if (!Array.isArray(newData)) {
      console.error("newData is not an array");
      return;
    }
    const updatedData = [...data];

    const [lectureHours, lectureMinutes] = lectureTime.split(':').map(Number);
    const lectureTotalMinutes = lectureHours * 60 + lectureMinutes;

    newData.forEach((item) => {
      // Check for duplicate docID
      if (existingDocIds.has(item.id)) return;
      existingDocIds.add(item.id);

      const formattedTime = String(timestampToTime(item.time));
      const [formattedHours, formattedMinutes] = formattedTime.split(':').map(Number);
      const formattedTotalMinutes = formattedHours * 60 + formattedMinutes;

      const timeDifference = formattedTotalMinutes - lectureTotalMinutes;
      const formattedTimeDifference = formatTimeDifference(timeDifference);

      const timeIndex = updatedData.findIndex((d) => d.time === formattedTimeDifference);

      if (timeIndex !== -1) {
        updatedData[timeIndex].confusion += 1;
      } else {
        updatedData.push({ ...item, time: formattedTimeDifference, confusion: 1 });
      }
    });

    setData(updatedData);
};

  const formatTimeDifference = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const timestampToTime = (newData) => {
  
    const seconds = newData._seconds;
    const nanoseconds = newData._nanoseconds;
  
    const totalMilliseconds = (seconds * 1000) + (nanoseconds / 1e6);
  
    const date = new Date(totalMilliseconds);
  
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    const regTime = convertMilitaryToHour(formattedTime)
    
    return regTime;
  };

  return (
    <Paper className={classes.graphContainer}>
      <Typography variant="h6" gutterBottom>
        Live Confusion Graph
      </Typography>
      <ResponsiveContainer width="97%" height="100%">
        <AreaChart data={data} margin={{ top: 10, right: 15, bottom: 10 }}>
          <defs>
            <linearGradient id="colorConfusion" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5a189a" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#5a189a" stopOpacity={0.1} />
            </linearGradient>
            <linearGradient id="colorDiscussion" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#495057" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#495057" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <Legend />
          <CartesianGrid vertical={false} strokeDasharray="7" />
          <XAxis dataKey="time" interval={9} tickLine={false} axisLine={false}/>
          <YAxis
            allowDecimals={false} // Prevents any decimal ticks from being generated
            interval={0} // Ensures that all ticks are shown
            domain={[0, 'auto']} // Adjusts the Y-axis to fit your data
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="discussion"
            stroke="#495057"
            fill="url(#colorDiscussion)"
            fillOpacity={0.4}
            stackId="a"
          />
          <Area
            type="monotone"
            dataKey="confusion"
            stroke="#5a189a"
            fill="url(#colorConfusion)"
            fillOpacity={0.4}
            stackId="a"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Paper>
  );
};

const LiveChat = () => {
  const classes = useStyles();
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const userId = localStorage.getItem("user_id");
  const { lectureId } = useParams();
  const [messages, setMessages] = useState([]);
  const [originalMessages, setOriginalMessages] = useState([]); // Store the original messages order
  const [newMessage, setNewMessage] = useState("");
  const [sortAnchorEl, setSortAnchorEl] = useState(null); // For sort menu
  const [isSortedByLikes, setIsSortedByLikes] = useState(false); // Track sorting state
  const [refreshComments, setRefreshComments] = useState(false); // State to trigger refetch
  const dummy = useRef();
  
  if (!db) {
    console.error("Firebase firestore is not initialized.");
  }

  useEffect(() => {
    try {
      const commentsCol = collection(db, `lectures/${lectureId}/comments`);
      const commentsQuery = query(commentsCol, orderBy("timestamp", 'asc'));
      console.log("list of comments", commentsQuery);
      const unsubscribe = onSnapshot(commentsQuery, (snapshot) => {
        const source = snapshot.metadata.hasPendingWrites ? "Local" : "Server";
        console.log(source);
        if (source !== "Local") {
          console.log(source);
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            snapshot.docChanges().forEach((change) => {
              console.log('Change detected:', change.type, change.doc.data());
              const commentData = change.doc.data();
              commentData.id = change.doc.id;
  
              if (commentData.userId === userId) {
                commentData.username = 'You';
              }
  
              if (commentData.text === "") {
                return;
              }
  
              // Add hasLiked property
              commentData.hasLiked =
                commentData.likedBy &&
                Array.isArray(commentData.likedBy) &&
                commentData.likedBy.includes(userId);
  
              if (change.type === "added") {
                // Prepend the new message at the beginning of the array
                updatedMessages.unshift(commentData);
              } else if (change.type === "modified") {
                const index = updatedMessages.findIndex(
                  (message) => message.id === commentData.id
                );
                if (index !== -1) {
                  updatedMessages[index] = commentData;
                }
              } else if (change.type === "removed") {
                const index = updatedMessages.findIndex(
                  (message) => message.id === commentData.id
                );
                if (index !== -1) {
                  updatedMessages.splice(index, 1);
                }
              }
            });
            // Update originalMessages whenever messages are updated
            setOriginalMessages(updatedMessages);
            return updatedMessages;
          });
        }
      });
  
      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [lectureId, userId, refreshComments]);
  

  const handleEditMessage = (id) => {
    const messageToEdit = messages.find(m => m.id === id);
    setEditingMessageId(id);
    setEditedMessage(messageToEdit.text);
  };

  const handleSaveEdit = (id) => {
    setMessages(messages.map(m =>
      m.id === id ? { ...m, text: editedMessage } : m
    ));
    setEditingMessageId(null);
    setEditedMessage("");
  };

  const handleLikeMessage = async (commentId) => {
    try {
      console.log("Logging all comments before liking:");
      console.log(messages); // Log all the comments currently listed
  
      // Optimistically update the UI
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === commentId
            ? {
                ...message,
                likes: (message.likes || 0) + 1,
                hasLiked: true, // Set hasLiked to true
              }
            : message
        )
      );
  
      // Send the like request to the backend
      const response = await axios.post(
        process.env.REACT_APP_POST_LIKE_COMMENT,
        {
          lectureId,
          commentId,
          userId, // Pass the current user's ID
        }
      );
  
      if (response.status !== 200) {
        console.warn("Unexpected response status:", response.status);
        // Roll back the optimistic update if the request fails
        setMessages((prevMessages) =>
          prevMessages.map((message) =>
            message.id === commentId
              ? {
                  ...message,
                  likes: (message.likes || 1) - 1,
                  hasLiked: false, // Reset hasLiked to false
                }
              : message
          )
        );
      }
    } catch (error) {
      console.error('Error liking message:', error);
  
      // Roll back the optimistic update if the request fails
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === commentId
            ? {
                ...message,
                likes: (message.likes || 1) - 1,
                hasLiked: false, // Reset hasLiked to false
              }
            : message
        )
      );
    }
  };  

  const handleDeleteMessage = (id) => {
    setMessages(messages.filter(m => m.id !== id));
  };

  const handleReplyToMessage = (id, replyText) => {
    setMessages(messages.map(m =>
      m.id === id ? { ...m, replies: [...(m.replies || []), { id: Date.now(), text: replyText, sender: "You" }] } : m
    ));
  };

  const handleSortMenuOpen = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortByLikes = () => {
    setMessages(prevMessages => 
      [...prevMessages].sort((a, b) => (b.likes || 0) - (a.likes || 0))
    );
    setIsSortedByLikes(true);
    handleSortMenuClose();
  };

  const handleSortByTime = () => {
    setMessages([...originalMessages]); // Reset to original order based on timestamp
    setIsSortedByLikes(false);
    handleSortMenuClose();
  };

  const handleMenuOpen = (event, messageId) => {
    setAnchorEl(event.currentTarget);
    setActiveMessageId(messageId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveMessageId(null);
  };

  const handleSendMessage = async () => {
    const filter = new profanity();
    if (filter.isProfane(newMessage)) {
      alert("Your message contains profanity and cannot be sent.");
      return;
    }
  
    let currentUsername = username;
  
    if (!currentUsername) {
      try {
        const response = await axios.post(process.env.REACT_APP_GET_USER_USERNAME, { userId });
        if (response.status === 200) {
          currentUsername = response.data.username;
          setUsername(currentUsername);
        } else {
          console.error('Failed to retrieve username:', response.status);
          return;
        }
      } catch(error) {
        console.log('Error getting username:', error);
        return;
      }
    }

    if (newMessage.trim()) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_POST_LECTURE_COMMENT,
          { userId, username: currentUsername, lectureId, text: newMessage }
        );
  
        if (response.status === 200) {
          setNewMessage("");
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };
  

  return (
    <>
      <Paper
        className={classes.chatContainer}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          position="sticky"
          display="flex"
          flexDirection="row"
          fullWidth
          style={{
            backgroundColor: "#FFFFFF",
            zIndex: 1000,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Live Discussion
          </Typography>
          <IconButton
            onClick={handleSortMenuOpen}
            style={{
              maxWidth: "24px",
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>

        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={handleSortMenuClose}
        >
          <MenuItem onClick={handleSortByLikes}>Sort by Likes</MenuItem>
          <MenuItem onClick={handleSortByTime}>Sort by Time</MenuItem>
        </Menu>

        <Box className={classes.messagesBox}>
          <List className={classes.messageList}>
            {messages.map((message) => (
              <ListItem key={message.id} className={classes.messageItem}>
                <div style={{ flexGrow: 1 }}>
                  <Typography>{`${message.username || "Unknown User"}: ${
                    message.text
                  }`}</Typography>
                </div>

                <div className={classes.likeButtonContainer}>
                <IconButton
                  onClick={() => handleLikeMessage(message.id)}
                  size="small"
                  style={{ padding: "4px" }}
                  disabled={message.hasLiked} // Disable if already liked
                >
                  <ThumbUpIcon
                    fontSize="small"
                    color={message.hasLiked ? "primary" : "inherit"} // Change color if liked
                  />
                  <Typography variant="caption" style={{ marginLeft: "2px" }}>
                    {message.likes || 0}
                  </Typography>
                </IconButton>
                </div>
              </ListItem>
            ))}
            <div ref={dummy}></div>
          </List>
        </Box>

        <Box className={classes.messageContainer}>
          <TextField
            fullWidth
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            placeholder="Type your message"
            className={classes.messageInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSendMessage} color="primary">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Paper>
    </>
  );
};

function ProfLecDash() {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem('user_id'));
  const [role, setRole] = useState(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!userId) {
        console.error('User ID not found');
        return;
      }

      try {
        const response = await axios.post(process.env.REACT_APP_GET_ROLE_URL, { userId });
        if (response.status === 200) {
          setRole(response.data);
        } else {
          alert('Invalid credentials');
        }
      } catch (error) {
        alert('Invalid credentials');
      }
    };

    fetchUserRole();
  }, [userId]);

  return (
    <Box>
      <AppBar position="fixed" sx={{ bgcolor: theme.palette.primary.main }}>
        <Toolbar>
          <Box className={classes.menuButton}>
            <Hamburger toggleSidebar={toggleSidebar} />
          </Box>
          <Typography variant="h6" className={classes.title}>
            Lecture Dashboard
          </Typography>
          <ProfilePicture userId={userId} /> {/* Calls Profile Pic Component */}
        </Toolbar>
      </AppBar>
      <Box className={classes.container}>
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        <Box className={classes.content}>
          <ConfusionGraph />
          <LiveChat />
        </Box>
      </Box>
      <Box className={classes.homebarContainer}>
        {role === 'Student' && <Homebar />}
      </Box>
    </Box>
  );
}

export default ProfLecDash;