import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { 
        main: "#5383EC" 
    },
    white: { 
        main: "#f8f8ff" 
    },
    black: { 
        main: "#0c0c0c" 
    },
    grey: { 
        main: "#ececec",
        dark: "#6c757d", 
    },
  },
});

export default theme;
